import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataContext } from './createContext';

const baseUrl = 'https://esports.hirezstudios.com/esportsAPI/smite';
let eventId = process.env.GATSBY_LEAGUE_ID;

class AppProvider extends Component {

  constructor() {
    super();
    this.state = {
	    actions: {
        updateStandingsPhase: this.updateStandingsPhase,
        setDarkTheme: this.setDarkTheme,
        setSpoilers: this.setSpoilers
      },
      ad: null,
      schedule: null,
      standings: null,
      standings2: null,
      teams: null,
      news: null,
      newsList: null,
      newsCarousel: null,
      baseUrl: baseUrl,
      eventId: eventId,
      darkTheme: true,
      hideSpoilers: false,
      now: Math.floor(Date.now() / 1000)
      // now: 1556809300
	  };
  }

  updateStandingsPhase = (phase) => {
    this.getStandings(phase)
  }

  setDarkTheme = (darkTheme) => {
    this.setState({darkTheme: darkTheme});
  }
  
  setSpoilers = (spoilers) => {
    console.log('Spoilers from setSpoilers',spoilers)
    this.setState({hideSpoilers: spoilers});
  }

  getSchedule = () => {
    fetch(`${baseUrl}/schedule/${eventId}`)
    .then(response => response.json())
    .then(response => {
      this.setState({schedule: response});
    });
  }

  getStandings = (phase) => {
    fetch(`${baseUrl}/standings/${eventId}${phase ? '/' + phase : ''}`)
    .then(response => response.json())
    .then(response => {
      this.setState({standings: response});
    });
  }
  getStandings2 = (s2ID) => {
    
    fetch(`${baseUrl}/standings/${s2ID}`)
    .then(response => response.json())
    .then(response => {
      this.setState({standings2: response});
    });
  }

  getTeams = () => {
    fetch(`${baseUrl}/teams/${eventId}`)
    .then(response => response.json())
    .then(response => {
      this.setState({teams: response});
    });
  }

  getAllNews = () => {
    fetch(`https://cms-esports.smitegame.com/wp-json/wp-api/get-posts/1?category=news+${process.env.GATSBY_LEAGUE_NEWS_CATEGORY}`)
    .then(response => response.json())
    .then(response => {
      this.setState({news: response});
    });
  }

  getHomeNews = () => {
    fetch(`https://cms-esports.smitegame.com/wp-json/wp-api/all-homepage-news/1${process.env.GATSBY_LEAGUE_NEWS_CATEGORY=='SCC'?`?league=${process.env.GATSBY_LEAGUE_NEWS_CATEGORY}`:''}`)
    .then(response => response.json())
    .then(response => {
      this.setState({
        newsCarousel: response[0],
        newsList: response[1]
      });
    });
  }

  getHomeAd = () => {
    fetch(`https://cms-esports.smitegame.com/wp-json/wp-api/promo-content?league=${process.env.GATSBY_LEAGUE_NEWS_CATEGORY}`)
    .then(response => response.json())
    .then(response => {
      this.setState({
        ad: response
      });
    });
  }

  componentDidMount() {
    this.getSchedule();
    this.getStandings();
    if(process.env.GATSBY_LEAGUE==='SCC'){
      this.getStandings2(7344);
    }
    this.getTeams();
    this.getAllNews();
    this.getHomeNews();
    this.getHomeAd();
  }

  render() {
    return <DataContext.Provider value={this.state}>{this.props.children}</DataContext.Provider>;
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;