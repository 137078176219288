import styled from 'styled-components';

export const ComponentWrapper = styled.nav`
  left: 0;
  order: 2;
  position: sticky;
  top: 0;
  z-index: 111;
  .switch {
    display: inline-block;
    height: 2.6rem;
    margin-right: 1.4rem;
    position: relative;
    width: 5rem;
    input {
      display: none;
    }
    .slider {
      background-color: #ccc;
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: .4s;
      &:before {
        background-color: #fff;
        bottom: .3rem;
        content: "";
        height: 2rem;
        left: .4rem;
        position: absolute;
        transition: .4s;
        width: 2rem;
      }
      &.round {
        border-radius: 3.6rem;
        &:before {
          border-radius: 50%;
        }
      }
    }
  }
  input:checked + .slider {
    background-color: var(--primary);
    &:before {
      transform: translateX(22px);
    }
  }

  .switch-spoiler {
    display:inline-block;
    margin-left: 5rem;
    color: white !important;
    margin: 2rem 0rem 2rem 1rem;
    max-width: fit-content;
    padding: 0.5rem 1.8rem;
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: pointer;
    background: var(--button-color) 0% 0% no-repeat padding-box;
    border-radius: 17px;
    opacity: 1;
    white-space:nowrap;
    text-align: left;
    font: normal normal bold 1.3rem Roboto;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    opacity: 1;
    margin-right:10px;

    
    .spoiler-wrapper{
      color:var(--button-text);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .exclamation-alert{
      display: inline-block;
      color:var(--button-text);
      height: 14px;
      width: 14px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left:0.6rem;
      filter:invert(1);
    }
      }
    input {
      display: none;
    }
  
  .desktop {
    background: var(--menu-background);
    border-bottom: .1rem solid  var(--border);
    height: 6rem;
    align-items: center;
    display: flex;
    backdrop-filter:blur(8px);
    -webkit-backdrop-filter:blur(8px);
    @media only screen and (max-width: 920px) {
      display: none;
    }
    .branding {
      align-items: center;
      display: flex;
      height: 6rem;
      justify-content: center;
      max-width: 11.4rem;
      padding: 0 1.6rem;
      width: 100%;
      i {
        color: #FFF;
        font-size: 3.6rem;
      }
    }
    .links {
      align-items: center;
      display: flex;
      height: 100%;
      a,
      div {
        align-items: center;
        color: var(--menu-text);
        display: flex;
        font-size: 1.6rem;
        height: 100%;
        margin: 0 1rem;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        transition: color .2s ease-in-out;
        &:hover {
          color: var(--primary);
        }
        &.is-hidden {
          display: none;
        }
        &.more {
          cursor: pointer;
          padding-right: 1.4rem;
          position: relative;
          &:after {
            border-bottom: .1rem solid white;
            border-right: .1rem solid white;
            content: "";
            display: block;
            height: .4rem;
            position: absolute;
            right: 0;
            top: 2.6rem;
            transform: rotate(45deg);
            transition: color .2s ease-in-out;
            width: .4rem;
          }
          .more-container {
            align-items: flex-start;
            background: var(--secondary-background);
            display: none;
            flex-direction: column;
            height: auto;
            left: 0;
            padding: 1rem;
            position: absolute;
            left: -2rem;
            top: 100%;
            z-index: 10;
            width: 24rem;
            a {
              color: var(---menu-text);
              line-height: 1.5;
              padding: 0.5rem 0 0.5rem 0.5rem;
              &:hover {
                color: var(--primary);
              }
            }
          }
          &.link:hover {
            .more-container {
              display: flex;
            }
          }
        }
      }
      .overflow {
        cursor: pointer;
        position: relative;
        .children {
          background: var(--menu-background);
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          height: auto;
          left: -2rem;
          max-height: 0;
          overflow: hidden;
          position: absolute;
          top: 6rem;
          width: 28rem;
          z-index: 1111;
          .link.more {
            box-sizing: border-box;
            height: auto;
            width: 100%;
          }
          span {
            box-sizing: border-box;
            color: var(--menu-text);
            line-height: 1.5;
            padding: 0.5rem 0 0.5rem 0.5rem;
            text-align: left;
            width: 100%;
          }
          a {
            box-sizing: border-box;
            color: var(--menu-text);
            line-height: 1.5;
            padding: 0.5rem 0 0.5rem 0.5rem;
            text-align: left;
            width: 100%;
            &:hover {
              color: var(--primary);
            }
          }
        }
        &:hover {
          .children {
            max-height: 200rem;
            padding: 1rem 2rem;
          }
        }
      }
    }
    .utility-wrapper {
      align-items: center;
      display: flex;
      margin-left: auto;
      .services {
        display: flex;
        div {
          align-items: center;
          background: var(--primary);
          cursor: pointer;
          display: flex;
          height: 6rem;
          justify-content: center;
          width: 6rem;
          &:first-of-type {
            margin-right: .1rem;
          }
        }
      }
      .switch{
        margin-left: 1.4rem;
        color: var(--menu-text);
      }
      .darkModeSwitch{
        font: normal normal bold 1.3rem Roboto;
        color: var(--menu-text);
        &.light{
          color: rgba(255, 255, 255, 1);
        }
        
      }
    }
    .play {
      background-color: var(--button-color);
      box-sizing: border-box;
      cursor: pointer;
      filter: brightness(1);
      height: 4rem;
      margin-left: .1rem;
      margin-right: .5rem;
      padding: 0 3rem;
      text-align: center;
      transition: .2s filter ease-in-out;
      &:hover {
        filter: brightness(1.2);
      }
      a {
        align-items: center;
        color: var(--button-text);
        display: flex;
        font-size: 1.6rem;
        font-weight: bold;
        height: 100%;
        margin: 0 1rem;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%;
      }
    }
  }
  .mobile {
    display: none;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 2px;
    width: 100%;
    @media only screen and (max-width: 920px) {
      display: block;
    }
    /* @media only screen and (max-width: 900px) {
      top: 5.9rem;
    } */
    .menu-header {
      align-items: center;
      background: rgba(42, 42, 42, 1);
      display: flex;
      height: 3.2rem;
      justify-content: space-between;
      left: 0;
      position: fixed;
      pointer-events: initial;
      top: 0;
      width: 100%;
      .icon-housing {
        background: rgba(112, 112, 112, 1);
        cursor: pointer;
        padding: .7rem .9rem;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .menu-icon {
        background: white;
        height: .2rem;
        position: relative;
        width: 1.2rem;
        transition: all 300ms ease;
        &:before,
        &:after {
          content: "";
          display: flex;
          background: white;
          height: .2rem;
          position: absolute;
          width: 1.2rem;
          top: -.6rem;
          transition: all 300ms ease;
          right: 0;
        }
        &:after {
          bottom: -.6rem;
          left: 0;
          top: initial;
          right: initial;
        }
      }
      .branding {
        cursor: pointer;
        text-align: center;
        width: 8rem;
        img {
          height: auto;
          width: 100%;
        }
      }
      .account {
        cursor: pointer;
        padding: 1rem;
        box-sizing: border-box;
        img {
          height: auto;
          width: 80%;
        }
      }
      
    }
    .menu-container {
      border-top: .1rem solid var(--border);
      box-shadow: 0 0 1rem rgba(0,0,0,0.25);
      height: calc(100vh);
      left: 0;
      max-width: 18.9rem;
      position: fixed;
      pointer-events: initial;
      width: 100%;
      top: 3.2rem;
      transform: translateX(-189px);
      transition: transform .2s ease-in-out;
      &.is-visible {
        transform: translateX(0);
      }
      @media only screen and (max-width: 768px) {
        background: transparent;
        box-shadow: none;
        transform: translateX(-100%);
        max-width: 100%;
      }
      .search {
        display: block;
        max-width: 18.9rem;
        width: 100%;
        @media only screen and (max-width: 768px) {
          /* max-width: 100%; */
        }
        input {
          background: var(--background);
          border: none;
          box-sizing: border-box;
          color: var(--menu-text);
          font-size: 1.2rem;
          outline: none;
          padding: 1.1rem 1.6rem;
          width: 100%;
          &::placeholder {
            color: var(--menu-text);
            font-size: 1.2rem;
          }
        }
      }
      .sidebar {
        background: var(--menu-background);
        height: 100%;
        max-width: 18.9rem;
        position: relative;
        width: 100%;
        .switch {
          margin-left: 1.5rem;
        }

        .mobile-spoiler{
          position: absolute;
          bottom: 3rem;
        }
      }
      .links {
        display: flex;
        flex-direction: column;
        img {
          max-width: 2.4rem;
        }
        .link {
          background: var(--menu-background);
          border-bottom: .1rem solid transparent;
          border-top: .1rem solid var(--border);
          box-sizing: border-box;
          color: var(--menu-text);
          display: flex;
          font-size: 1.2rem;
          padding: 1.5rem;
          text-decoration: none;
          text-transform: uppercase;
          transition: border .2s ease-in-out;
          &:last-of-type {
            border-bottom-color: var(--border);
          }
          &:hover {
            border-color: var(--primary);
          }
          &.more {
            cursor: pointer;
            .arrow {
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent; 
              border-right: 12px solid var(--secondary-background);
              height: 0;
              opacity: 0;
              pointer-events: none;
              position: absolute;
              right: 0;
              transition: .2s;
              width: 0;
            }
            .link-container {
              align-items: center;
              display: flex;
              justify-content: space-between;
              padding: 0;
              width: 100%;
              .link.sub {
                border: none;
                padding: 0;
                position: relative;
              }
            }
            .more-container {
              background: var(--menu-background);
              display: flex;
              flex-direction: column;
              height: 100%;
              left: 18.9rem;
              opacity: 0;
              padding: 3rem;
              pointer-events: none;
              position: absolute;
              top: -3.8rem;
              transition: .2s;
              width: calc(100vw - 18.9rem);
              &.is-hidden {
                display:none;
              }
              &:hover {
                opacity: 1;
                pointer-events: auto;
              }
              a {
                color: var(--menu-text);
                text-decoration: none;
              }
              .link {
                align-items: center;
                background: var(--menu-background);
                border: none;
                display: flex;
                padding-left: 0;
                transition: .2s;
                &:hover {
                  color: var(--primary);
                }
                img {
                  margin-right: 1rem;
                }
              }
            }
            &:hover {
              .arrow {
                opacity: 1;
              }
              .more-container {
                opacity: 1;
                pointer-events: auto;
              }
            }
          }
        }
      }
    }
    .darkModeSwitchWrapper{
      display:flex;
      flex-direction:row;
      align-items:center;
      margin: 1.5rem 0 0 1.5rem;
    }
    .darkModeSwitch{
      font: normal normal bold 1.3rem Roboto;
      color: var(--menu-text);
      &.light{
        color: var(--menu-text);
      }
    }
  }
`;